import React from "react";
import css from "./common.module.css";
import img from "../assets/p1.jpg";

const Layout = () => {
  return (
    <div className={css.layout}>
      <header className={css.header}>
        <div className={css.headerContent}>
          <h1 className={css.name}>Taimoor Fazal</h1>
          <nav className={css.nav}>
            <a href="#about" className={css.navItem}>
              About
            </a>
            {/* <a href="#projects" className={css.navItem}>
              Projects
            </a> */}
            <a href="#contact" className={css.navItem}>
              Contact
            </a>
          </nav>
        </div>
      </header>
      <main className={css.content}>
        <section id="about" className={css.section}>
          <div className={css.profilePic}>
            <img src={img} alt="Taimoor Fazal" />
          </div>
          <p>
            Welcome to my personal page! If you're here by mistake, well, it's
            too late now – you're stuck with me. If you meant to be here, buckle
            up and enjoy the ride!
          </p>
          <h2>About Me</h2>
          <div>
            <p>
              Hello! I'm Taimoor, a technology enthusiast originally from
              Pakistan, now residing in the beautiful (and sometimes chilly)
              country of Norway. I'm always on the lookout for the latest
              advancements in technology – yes, I'm that person who gets excited
              about new software updates and tech gadgets.
            </p>
            <p>
              I have a Master's degree in Technology and a Bachelor's degree in
              Computer Sciences. Since 2018, I've been working as a software
              engineer on and off, which means I've had my fair share of coding
              marathons and debugging nightmares.
            </p>
            <p>
              When I'm not buried in code, you'll find me indulging in my other
              passions. I love to write – whether it's about technology, random
              musings, or anything that catches my fancy. Photography is another
              hobby of mine; there's something magical about capturing moments
              through the lens. And let’s not forget my culinary adventures –
              experimenting with new recipes and cooking up a storm in the
              kitchen is my way of unwinding.
            </p>
            <p>
              Travel used to be a big part of my life. I've been fortunate
              enough to explore 10 different countries so far. While my
              globetrotting days have slowed down, my curiosity about different
              cultures and places hasn't waned. So, that's a bit about me. If
              you have any questions, feel free to reach out. Or don't – no
              pressure.
            </p>
          </div>
          <h2>Coming Soon</h2>
          <div>
            <p>
              Brace yourselves! This section will soon be filled with tales from
              my various adventures, complete with photos and videos that might
              just make you jealous. I'll be writing my heart out about my
              experiences, the numerous trips I've taken, and sharing the little
              details that only the truly curious would care about.
            </p>
          </div>
        </section>
      </main>
      <footer id="contact" className={css.footer}>
        <p>
          Think you've got what it takes to reach out? Drop me a line at
          taimoorfazal94@gmail.com – if you dare 😏
        </p>
      </footer>
    </div>
  );
};

export default Layout;
